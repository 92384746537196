/* Navbar Styles */
.navbar {
    position: absolute;  /* Use absolute positioning */
    top: 20px;           /* Adjust the vertical position from the top */
    right: 20px;         /* Place the navbar on the right side */
    padding: 10px;
    display: flex;       /* Flexbox to align items in a row */
    justify-content: space-around;  /* Space out the items evenly */
    align-items: center; /* Vertically center the items */
    border-radius: 5px;
    z-index: 1000;       /* Ensure the navbar is on top of other content */
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex; /* Align the list items in a row */
  }
  
  .navbar li {
    margin-right: 100px;  /* Space between items */
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: bold;
  }
  
  .navbar li:hover {
    color: #ddd;
  }
  
  /* Add smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  /* Section Styles */
  .AboutSection {
    padding: 60px 20px;
    margin-top: 30px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
  }
  h3{
    text-align: center;
    max-width: 400px;
	font-weight: 900;
	margin-left: 730px;
}
  

  .ContactSection {
    padding: 60px 20px;
    margin-top: 30px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
  }
  
  .box-container2 {
    display: flex;               /* Enable Flexbox layout */
    flex-wrap: wrap;             /* Allow wrapping of items into new rows */
    justify-content: px; /* Optionally, add space between columns */
    row-gap: 30px;
    column-gap: 100px;                   /* Add space between boxes (optional) */
    margin-left: 140px;
  }
  
  .box2 {
    width: 400px;                  /* Set the width to around 45% of the container */
    padding: 10px 30px;
    background-color: #ffffff;      /* Light gray background */
    text-align: center;          /* Center the text inside the box */
    border: 1px solid #ccc;      /* Add border to each box */
    border-radius: 5px;          /* Rounded corners for the boxes */
  }

  h2 {
    font-size: 12px;
    text-align: center;
    margin-top:5px;
  }
  h4 {
    font-weight: lighter; 
    text-align: center;
  }

  h5 {
    font-size: 20px;
    margin-top:15px;
    margin-right:25px;
    font-weight: lighter; 
    text-align: center;
  }
  
  #signin {
    background-color: #e5e8ec; /* Optional: Change for Sign In section */
  }
  
  /* Sign-In Form Styles */
  body {
    background-color: #e5e8ec;      /* Light gray background color */
  }

  .login-container {
    max-width: 400px;
    margin: 295px auto;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  button {
    width: 100px;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 5px;
  }
  
  button:hover {
    background-color: #444;
  }
  
  #message {
    color: red;
    text-align: center;
    margin-top: 10px;
  }

  /* 修改 h2 样式，但只针对登录容器内的 h2 */
  .login-container h2 {
    font-size: 32px;  /* 增加字体大小 */
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;  /* 增加一些底部间距 */
    font-weight: bold;    /* 加粗字体 */
    color: #333;         /* 更深的颜色 */
  }

  /* 保持其他 h2 的样式不变 */
  h2:not(.login-container h2) {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }
