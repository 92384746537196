/* App.css */
.App {
  text-align: center;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
  transition: transform 0.5s ease-in-out;
}

.App-logo:hover {
  transform: scale(1.05);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 18s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: #ffffff;
  padding: 20px;
}

.App-link {
  color: #61dafb;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
}

.App-link:hover {
  color: #21a1f1;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* 移除横线 */
.horizontal-line {
  display: none;  /* 隐藏横线 */
}

/* 导航栏样式 */
.radio-inputs {
  position: absolute;
  top: 50px;
  left: 30px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #EEE;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.1rem;
  width: 500px;
  font-size: 16px;
}

/* 添加 Link 样式 */
.radio-inputs .radio a {
  text-decoration: none;  /* 移除默认的下划线 */
  color: inherit;
  display: block;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: .5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all .15s ease-in-out;
  position: relative;
}

/* 修改选中状态的样式 */
.radio-inputs .radio input:checked + .name {
  background-color: white;  /* 白色背景 */
  font-weight: normal;
  color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* 添加轻微阴影 */
  border-radius: 0.5rem;  /* 圆角 */
  padding: 0.5rem 1rem;   /* 增加一些内边距 */
}

/* 悬停效果只在未选中时显示 */
.radio-inputs .radio input:not(:checked) + .name::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #007bff;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

/* 未选中项的悬停效果 */
.radio-inputs .radio input:not(:checked) + .name:hover::after {
  width: 70%;
}
